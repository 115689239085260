import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ModalHandler from '@withdiver/components/src/modals/ModalHandler'
import dark from '@withdiver/components/src/theme/dark'
import { GlobalStyle } from '@withdiver/components/src/theme/GlobalStyle'
import light from '@withdiver/components/src/theme/light'
import Theme from '@withdiver/components/src/theme/Theme'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { Assistant } from './Assistant'
import { AuthProvider } from './AuthProvider'
import { useGetMeQuery } from './generated/graphql'
import Activity from './pages/Activity'
import Alerts from './pages/Alerts'
import CommandLineAuth from './pages/Auth/CommandLineAuth'
import CommandLineAuthCompleted from './pages/Auth/CommandLineAuthCompleted'
import GithubAuth from './pages/Auth/GithubAuth'
import Billing from './pages/Billing'
import Connections from './pages/Connections'
import Dashboard from './pages/Dashboard'
import DashboardList from './pages/DashboardList'
import Filters from './pages/Filters'
import Settings from './pages/Settings'
import { SharedPageWrapper } from './pages/Shared/SharedPageWrapper'
import SharedDashboardList from './pages/Shared/DashboardList'
import Team from './pages/Team'
import { useGraphQLClient } from './useGraphQLClient'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
})

tippy.setDefaultProps({ duration: 150 })

const themes: { [key: string]: Theme } = {
	dark,
	light,
}

function DefaultOrganizationChooser() {
	const graphQLClient = useGraphQLClient()
	const { data } = useGetMeQuery(graphQLClient)

	const organizationId = data?.me.organizations?.[0].id
	if (organizationId) {
		return <Navigate to={`/workplace/${organizationId}`} replace/>
	}

	return null
}

function Redirect({ to }: { to: string }) {
	const params = useParams()
	const page = params?.['*'] ?? ''
	window.location.replace(`${to}/${page}`)
	return null
}

const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)")

function App() {
	const autoTheme = colorSchemeQuery.matches ? 'dark' : 'light'
	const forcedTheme = localStorage.getItem('theme')
	const [ theme, setTheme ] = useState<keyof typeof themes>(forcedTheme ?? autoTheme)

	useEffect(() => {
		colorSchemeQuery.addEventListener('change', (e) => {
			setTheme(forcedTheme ?? (e.matches ? 'dark' : 'light'))
		})
		// TODO: Remove listener on unmount
	}, [ forcedTheme ])

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={themes[theme]}>
				<GlobalStyle theme={themes[theme]}/>
				<BrowserRouter>
					<ModalHandler zIndex={1000}>
						<Routes>
							<Route path="shared/:shareId/*" element={
								<SharedPageWrapper>
									<Routes>
										<Route path="dashboards">
											<Route index element={<SharedDashboardList/>}/>
										</Route>
										<Route index element={<Navigate to="dashboards" replace/>}/>
									</Routes>
								</SharedPageWrapper>
							}/>
							<Route path="*" element={
								<AuthProvider>
									<Routes>
										<Route path="workplace/:organizationId/*" element={<Assistant/>}/>
									</Routes>
									<Routes>
										<Route path="workplace/:organizationId">
											<Route path="dashboards">
												<Route path="groups/:groupId" element={<DashboardList/>}/>
												<Route path=":dashboardId" element={<Dashboard/>}/>
												<Route index element={<DashboardList/>}/>
											</Route>
											<Route path="filters" element={<Filters/>}/>
											<Route path="alerts" element={<Alerts/>}/>
											<Route path="activity" element={<Activity/>}/>
											<Route path="team/*" element={<Team/>}/>
											<Route path="connections/*" element={<Connections/>}/>
											<Route path="billing/*" element={<Billing/>}/>
											<Route path="settings" element={<Settings/>}/>
											<Route index element={<Navigate to="dashboards" replace/>}/>
										</Route>
										<Route path="external/dbt/docs/*" element={<Redirect to="https://docs.getdbt.com"/>}/>
										<Route path="docs/*" element={<Redirect to="https://docs.withdiver.com"/>}/>
										<Route path="status" element={<Redirect to="https://diver.statuspage.io"/>}/>
										<Route path="auth/cli" element={<CommandLineAuth/>}/>
										<Route path="auth/cli/complete" element={<CommandLineAuthCompleted/>}/>
										<Route path="auth/github" element={<GithubAuth/>}/>
										<Route index element={<DefaultOrganizationChooser/>}/>
									</Routes>
								</AuthProvider>
							}/>
						</Routes>
					</ModalHandler>
				</BrowserRouter>
			</ThemeProvider>
		</QueryClientProvider>
	)
}

export default App
