import { useQueryClient } from '@tanstack/react-query'
import { Card } from '@withdiver/components/src/Card'
import { Dashboard, DashboardItem, Spinner } from '@withdiver/components/src/Dashboard'
import { Button } from '@withdiver/components/src/Button'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import {
	ChevronRight,
	Folder,
	Loader,
	MoreVertical,
	Plus,
} from 'react-feather'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Dashboard as DashboardType, useDeleteDashboardMutation, useGetDashboardsQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import Sidebar from '../Sidebar'
import { DashboardModal } from './DashboardModal'
import { DashboardGroupsModal } from './DashboardGroupsModal'

function DashboardListPage() {
	const { show } = useModal()
	const { id: organizationId } = useOrganization()
	const { groupId } = useParams<{ groupId?: string }>()
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { data, isLoading } = useGetDashboardsQuery(graphQLClient, { organizationId })
	const deleteDashboard = useDeleteDashboardMutation(graphQLClient)
	const theme = useTheme() as Theme

	const onDashboardGroupsClick = useCallback((dashboardId: string) => (e) => {
		e.preventDefault()
		return show(modalProps => (
			<DashboardGroupsModal
				dashboardId={dashboardId}
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	const onDeleteDashboardClick = useCallback((dashboardId: string) => async () => {
		try {
			await deleteDashboard.mutateAsync({
				dashboardId,
			})
			await queryClient.invalidateQueries({ refetchType: 'all' })
		} catch (e) {
			console.error(e)
		}
	}, [ deleteDashboard, queryClient ])

	const onEditDashboardClick = useCallback((dashboard: DashboardType) => {
		return show(modalProps => (
			<DashboardModal
				graphQLClient={graphQLClient}
				dashboard={dashboard}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, show ])

	const onNewDashboardClick = useCallback(() => {
		return show(modalProps => (
			<DashboardModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, organizationId, show ])

	const selectedGroup = groupId ? data?.me.organization?.dashboardGroups.find(g => g.id === groupId) : undefined

	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text alignItems="center" color="text" fontSize={24}>
						{Boolean(groupId) || 'Dashboards'}
						{groupId &&
						<>
							<TextLink to="..">Dashboards</TextLink>
							<View as={ChevronRight} mx={1}/>
							{selectedGroup?.name}
						</>
						}
						<Button
							variant="primary"
							size="icon"
							alignSelf="center"
							width="fit-content"
							onClick={onNewDashboardClick()}
							ml={1}
						>
							<View as={Plus} size={20}/>
						</Button>
					</Text>
				</View>
				{isLoading &&
				<Spinner as={Text} color="text" justifyContent="center" alignItems="center" height="100%">
					<View as={Loader} size={88}/>
				</Spinner>
				}
				{Boolean(groupId) ||
				<View
					display="grid"
					gap="20px"
					gridTemplateColumns="repeat(auto-fill, minmax(220px, max-content))"
					mb={4}
				>
					{data?.me.organization?.dashboardGroups.map(group => (
						<Card
							as={TextLink}
							color="text"
							key={group.id}
							p={1}
							to={`groups/${group.id}`}
						>
							<View alignItems="center">
								<Text color="text">
									<View as={Folder} mr={1} size={20}/>
								</Text>
								<Text
									color="text"
									mx={1}
									fontSize={16}
								>
									{group.name}
								</Text>
								<View flexGrow={1}/>
								<OverflowActionMenu
									icon={MoreVertical}
									size={16}
								>
									<TextLink
										color="text"
										to=""
									>
										Edit
									</TextLink>
									<TextLink
										color="text"
										to=""
									>
										Share
									</TextLink>
								</OverflowActionMenu>
							</View>
						</Card>
					))}
					<Card
						as={TextLink}
						color="text"
						p={1}
						width="max-content"
					>
						<Text alignItems="center" color="text" fontSize={16}>
							<View as={Plus} mr={1} size={20}/>
							Add Group
						</Text>
					</Card>
				</View>
				}
				<View
					display="grid"
					gap="20px"
					gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
				>
					{data?.dashboards.filter(dashboard => !groupId || dashboard.dashboardGroups.some(dashboardGroup => dashboardGroup.id === groupId)).map(dashboard => (
						<Card
							borderRadius={20}
							color="text"
							flexDirection="column"
							height="fit-content"
							margin="auto"
							width="fit-content"
							key={dashboard.id}
							as={TextLink}
							to={`/workplace/${organizationId}/dashboards/${dashboard.id}`}
							p={10}
							mb={10}
						>
							<View justifyContent="space-between">
								<View>
									{dashboard.name} ({dashboard.chartCount} charts)
								</View>
								<OverflowActionMenu
									icon={MoreVertical}
									size={16}
								>
									<TextLink
										color="text"
										onClick={onEditDashboardClick(dashboard)}
										to=""
									>
										Edit
									</TextLink>
									<TextLink
										color="text"
										onClick={onDashboardGroupsClick(dashboard.id)}
										to=""
									>
										Groups
									</TextLink>
									<TextLink
										color="danger"
										onClick={onDeleteDashboardClick(dashboard.id)}
										to=""
									>
										Delete
									</TextLink>
								</OverflowActionMenu>
							</View>
							<View mt={2}/>
							<Dashboard
								columns={dashboard.columns}
								rows={dashboard.rows}
								height={200}
								width={300}
								pointerEvents="none"
								backgroundColor={theme.dashboard.backgroundColor}
								gap={5}
								p={10}
							>
								{dashboard.charts.map(chart => {
									return (
										<DashboardItem
											key={chart.id}
											padding={0}
											height={chart.height}
											width={chart.width}
											x={chart.x}
											y={chart.y}
										/>
									)
								})}
							</Dashboard>
						</Card>
					))}
				</View>
			</View>
		</View>
	)
}

export default DashboardListPage
